import React from 'react'

const footer = () => (
    <div className="footer-gradient">
      <div className="footer-dark">
          <footer>
              <div className="container">
                  <div className="row">
                      <div className="col-sm-6 col-md-3 item helpful-links">
                          <h3>Helpful Links</h3>
                          <ul>
                              <li><a href="menu">Menu</a></li>
                              <li><a href="gallery">Gallery</a></li>
                              <li><a href="about">About</a></li>
                          </ul>
                      </div>
                      <div className="col-sm-6 col-md-3 item">
                          <h3>Contacts</h3>
                          <ul>
                              <li><a href="tel:+16197027160" className="phone-number">(619)702-7160</a></li>
                              <li><a href="https://www.google.com/maps/dir//947+E+St,+San+Diego,+CA+92101/@32.7144993,-117.1579606,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x80d9535f34dffe09:0x7276d3b5766d8776!2m2!1d-117.1557719!2d32.7144993!3e0" className="address">947 E Street<br/>Downtown San Diego, CA<br/></a></li>
                              <li><a href="contact" className="hours">Monday - Sunday, 10AM - 9PM</a></li>
                          </ul>
                      </div>
                      <div className="col-sm-6 col-md-3 item">
                          <h3>Stay in Touch</h3>
                          <ul className="stay-in-touch">
                              <li><a href="https://www.instagram.com/pokezmexicanrestaurant/?hl=en" className="instagram-icon icon" target="_blank" rel="noreferrer">Instagram</a></li>
                              <li><a href="https://www.yelp.com/biz/pokez-san-diego" className="yelp-icon icon" target="_blank" rel="noreferrer">Yelp</a></li>
                              <li><a href="https://www.facebook.com/pages/category/Mexican-Restaurant/Pokez-Mexican-Restaurant-120021014676841/" className="facebook-icon icon">Facebook</a></li>
                              <li><a href="https://www.google.com/search?q=pokez&rlz=1C1CHBF_enUS894US894&oq=pokez&aqs=chrome.0.69i59l2j46j0l2j46j69i60l2.811j0j4&sourceid=chrome&ie=UTF-8#lrd=0x80d9535f4aa75a17:0xcb6ff83be8f6e680,1,,," className="google-icon icon">Google</a></li>
                          </ul>
                      </div>
                  </div>
                  <div className="footer-bottom container">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="copyright">© 2020 All Rights Reserved.</p>
                      </div>
                      <div className="col-md-6">
                        <p className="website-by">
                          <a href="https://tragic.media">Website by&nbsp;</a>
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
          </footer>
      </div>
    </div>
  )

export default footer;