import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'

const Header = ({ siteTitle }) => (
    <Navbar className="primary-nav" expand="lg" sticky="top">
        <Container className="px-lg-3 px-0">
            <Navbar.Brand className="logo d-none d-lg-block" href="/">Pokez</Navbar.Brand>
            <div className="mobile-wrapper d-flex d-lg-none">
                <div className="top-nav-mobile">
                    <div className="pre-wrap d-flex align-items-center flex-row-reverse">
                        <Link className="btn btn-primary postmates outline d-none d-sm-block d-lg-none" to="https://postmates.com/merchant/pokez-mexican-restaurant-san-diego">Get Delivery</Link>
                        <Link className="btn btn-primary uber outline d-none d-sm-block d-lg-none mr-xl-4 mr-2" to="https://www.ubereats.com/san-diego/food-delivery/pokez-mexican-restaurant/Xlt0Xj7ZSYK9kWa67tzyeQ">Uber Eats</Link>
                        <Link className="phone-link d-block d-lg-none" href="tel:+16197027160">(619)702-7160</Link>
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                <div className="togo d-block d-sm-none">
                    <Link className="btn btn-primary postmates outline" to="https://postmates.com/merchant/pokez-mexican-restaurant-san-diego">Get Delivery</Link>
                    <Link className="btn btn-primary uber outline" to="https://www.ubereats.com/san-diego/food-delivery/pokez-mexican-restaurant/Xlt0Xj7ZSYK9kWa67tzyeQ">Uber Eats</Link>
                </div>
            </div>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between align-items-center text-center">
                <Nav className="mx-auto nav nav-wrap">
                    <Nav.Item as="li" className="d-block d-lg-none">
                        <Link className="nav-link" to="/">Home</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link" to="/menu">Menu</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link" to="/gallery">Gallery&nbsp;</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link" to="/about">About</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link" to="/contact">Contact</Link>
                    </Nav.Item>
                </Nav>
                <Nav className="d-none d-lg-flex">
                    <Nav.Item as="li" className="align-items-center d-flex">
                        <Link className="phone-link mr-xl-4 mr-2" href="tel:+16197027160">(619)702-7160</Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mr-xl-4 mr-2">
                        <Link className="btn btn-primary postmates outline" to="https://postmates.com/merchant/pokez-mexican-restaurant-san-diego">Get Delivery</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="btn btn-primary uber outline" to="https://www.ubereats.com/san-diego/food-delivery/pokez-mexican-restaurant/Xlt0Xj7ZSYK9kWa67tzyeQ">Uber Eats</Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
